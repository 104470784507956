/* Global CSS */

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Montserrat";
}

/* Techno */
.techno {
    width: fit-content;
    height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #d6e3f8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.techno-txt {
    font-size: 15px;
    color: black;
}


/* Header */
header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    margin-top: 10px;
}


/* Nav */
nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: fit-content;
    height: 55px;
    column-gap: 20px;
    margin-right: 50px;
}

nav>ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

nav>ul>li {
    margin-left: 5px;
    margin-right: 5px;
}

.nav-elm {
    width: fit-content;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 200ms linear;
    text-decoration-line: none;

}

.nav-elm:hover {
    background-color: #183B56;
    transform: rotate(-5deg);
    padding-left: 10px;
    padding-right: 10px;
}

.nav-elm:hover>.nav-elm-txt,
.nav-elm:hover>.nav-icon {
    color: white;
    fill: white;
    cursor: pointer;
}

.nav-icon {
    margin-top: 2.5px;
}

.nav-elm-txt {
    font-size: 22px;
    color: #183B56;
    font-weight: 500;
    padding-left: 5px;
    text-transform: uppercase;
}


/* Logo */
.svg {
    display: block;
    font-size: 170px;
    width: fit-content;
    height: 80px;
    padding: 0px;
    margin-top: -17px;
}

.text-copy {
    fill: none;
    stroke: white;
    stroke-dasharray: 6% 29%;
    stroke-width: 6px;
    stroke-dashoffset: 0%;
    animation: stroke-offset 6.5s infinite linear;
}

.text-copy:nth-child(1) {
    stroke: #4D163D;
    animation-delay: -1;
}

.text-copy:nth-child(2) {
    stroke: #840037;
    animation-delay: -2s;
}

.text-copy:nth-child(3) {
    stroke: #BD0034;
    animation-delay: -3s;
}

.text-copy:nth-child(4) {
    stroke: #BD0034;
    animation-delay: -4s;
}

.text-copy:nth-child(5) {
    stroke: #FDB731;
    animation-delay: -5s;
}

.text-copy:nth-child(6) {
    stroke: rgb(22, 78, 138);
    animation-delay: -6s;
}

@keyframes stroke-offset {
    100% {
        stroke-dashoffset: -35%;
    }
}


/* Home */
.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.home>span {
    display: block;
    height: 2px;
    width: 10%;
    background-color: #183B56;
    margin-bottom: 30px;
    margin-top: 30px;
    border-radius: 1px;
    /* animation: divider-anim 20s linear infinite; */
}

.home-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 12.5%;
    padding-right: 12.5%;
    column-gap: 150px;
}

.home-div>img {
    height: 500px;
    border-radius: 250px;
    transform: rotate(10deg);
    background-position-x: right;
    box-shadow: 25px -10px 5px -5px #183B56;
}

.home-desc {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin-right: 10px;
    margin-left: 10px;
}



@keyframes divider-anim {
    0% {
        width: 30%;
    }

    50% {
        width: 5%;
    }

    100% {
        width: 30%;
    }
}

.home-top {
    font-size: 20px;
}

.home-desc>h2 {
    font-size: 80px;
    color: #183B56;
    margin-top: 10px;
    margin-bottom: 10px;
}

.home-bot {
    font-size: 25px;
}


/* Thumb */
.thumb {
    width: 100%;
    height: 320px;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 0px;
    z-index: 1;
}

.thumb>img {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    object-fit: cover;
    border-radius: 10px;
    border: none;
}


/* Projets */
.projets-div {
    position: relative;
    background-color: #183B56;
}

.projets-div>h2 {
    font-size: 35px;
    color: white;
    font-weight: 500;
    padding-top: 30px;
    margin-bottom: 15px;
}

.indicator-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.indicator-info>.indicator-info-logo {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: green;
    margin-right: 20px;
}

.indicator-info>.indicator-info-txt {
    color: white;
    font-size: 18px;
}

.projets {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    padding-left: 5%;
    padding-right: 5%;
    padding-top: 45px;
    padding-bottom: 35px;
    flex-wrap: wrap;
    row-gap: 25px;
}

.projet {
    width: 45%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    text-decoration-line: none;
}

.projet-desc {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    padding-left: 10px;
    padding-bottom: 10px;
    z-index: 0;
    position: relative;
    top: -10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.projet-desc>span {
    height: 1.5px;
    width: 100%;
    background-color: #183B56;
    margin-top: 20px;
    margin-left: -5px;
}

.projet-desc>p {
    width: 100%;
    font-size: 17px;
    padding-bottom: 5px;
    color: #183B56;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    margin-left: 2.5px;
    padding-right: 10px;
}

.technos {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 20px;
    row-gap: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
    max-width: calc(100% - 50px);
}


/* Indicator */
.indicator {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: gray;
    top: 30px;
    right: 15px;
}

.good {
    background-color: green;
}


/* Infos - CV */
.infos-cv-bloc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 80px;
    padding-bottom: 80px;
}

.infos-cv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #183B56;
    width: 100%;
    border-radius: 20px;
    padding: 20px;
    padding-bottom: 40px;
}

.infos-cv>h2 {
    font-size: 35px;
    font-weight: 500;
    color: white;
    margin-bottom: 25px;
}

.infos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 170px;
    margin-bottom: 15px;
}

.btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 30%;
    height: 100%;

    background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 30%);
}

.btns>h3 {
    font-size: 20px;
    font-weight: 500;
    color: #183B56;
}

.btn {
    background-color: lightblue;
    width: fit-content;
    height: fit-content;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    text-decoration-line: none;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #183B56;
    transition: all 200ms linear;
    text-decoration-line: none;

}

.btn:hover {
    background-color: #183B56;
    transform: rotate(-5deg);
    padding-left: 20px;
    padding-right: 20px;
    color: white;
}

.btn-icon {
    color: #183B56;
}

.btn:hover>.btn-icon {
    color: white;
    cursor: pointer;
}


.btn>svg {
    margin-left: 10px;
}

.stacks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    border-radius: 20px;
    width: 60%;
    height: 100%;
    padding-left: 10px;
    background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 30%);
}

.stack-elm {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    column-gap: 10px;
    margin-left: 10px;
}

.stack-elm>p {
    color: #183B56;
}

.stack-technos {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 20px;
    row-gap: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.stack-icon:hover {
    animation: rotation 500ms ease-in;
}

@keyframes rotation {
    to {
        transform: rotate(-360deg);
    }
}

.infos-cv>span {
    height: 2px;
    width: 50%;
    background-color: white;
    margin-bottom: 40px;
    margin-top: 40px;
    border-radius: 1px;
    /* animation: divider-anim 20s linear infinite; */

}

.mail {
    font-size: 25px;
    color: white;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 20px;
}

.socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    padding-top: 15px;
    padding-bottom: 5px;
}

.social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    width: 45px;
    height: 45px;
    border-radius: 22.5px;
    background-color: rgba(255, 255, 255, 0.95);
    border: solid 0.1px #ececec;
    outline: none;
    cursor: pointer;
    box-shadow: -5px -5px 30px rgba(255, 255, 255, 0.15),
        10px 10px 15px rgba(70, 70, 70, 0.15);
    /* transition: all 300ms ease; */
}

/* 
.social:hover {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    transition: all 500ms ease;
} */

.social-icon {
    color: #183B56;
    font-size: 24px;
    transition: all 500ms ease;
}

.social:hover>.social-icon {
    color: salmon;
    font-size: 30px;
    transition: all 400ms ease;
    /* animation: rotation 500ms ease-in; */
}


/* Footer */
footer {
    width: 100%;
    height: 300px;
    background-color: black;
    display: flex;
    flex-direction: column;
}

.footer-top {
    width: 100%;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.back-top {
    text-decoration-line: none;
    font-size: 20px;
    color: white;
    transition: all 400ms ease;
}

.back-top:hover {
    text-decoration-line: underline;
    font-size: 22px;
    color: lightblue;
    transition: all 400ms ease;
}

.footer-bot {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;
}

.copyright,
.year {
    font-size: 18px;
    color: white;
    font-weight: 400;
}